@import "@ng-select/ng-select/themes/material.theme.css";
@import "assets/styles/ng-select.scss";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-form-field {
  width: 100%;
}

.dialog-no-padding mat-dialog-container {
  padding: 0;
}

// Tablas
* {
  mat-cell {
    padding: 3px;
    span {
      overflow: hidden;
    }
  }

  .no-info {
    font-style: italic;
    color: gray;
  }
}
// Tablas

// SnackBar
* {
  .red-snackbar {
    background-color: #f44336;
    color: white;
  }
  .green-snackbar {
    background-color: #4caf50;
    color: white;
  }
  .yellow-snackbar {
    background-color: #ffeb3b;
    color: black;
  }
}
// Scroll Bar
* {
  ::-webkit-scrollbar {
    width: 22px;
    // background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-track {
    margin-left: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    // background-color: #f5f5f5;
    background-clip: content-box;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #7b1fa2;
    background-clip: content-box;
    border: 5px solid transparent;
  }
}
