.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  color: white;
}
.ng-select.ng-select-focused {
  .ng-select-container {
    .ng-value-container .ng-placeholder {
      color: #7b1fa2;
      opacity: 1;
    }

    .ng-arrow-wrapper .ng-arrow {
      color: #7b1fa2;
      opacity: 1;
    }
  }

  .ng-select-container:after {
    border-color: #7b1fa2;
    opacity: 1;
  }
}
.ng-select {
  padding: 0;
  margin-bottom: 22px;
  height: 56px;
  border-radius: 4px 4px 0 0;

  .ng-select-container {
    height: 100%;
    padding: 0 1em;
    color: white;

    .ng-value-container {
      padding-top: 20px;
      .ng-placeholder {
        color: white;
      }
    }

    .ng-arrow-wrapper .ng-arrow {
      color: white;
      margin-right: 0px;
    }
  }

  .ng-select-container:after {
    border-bottom: 1px solid white;
  }

  .ng-clear {
    color: white !important;
  }

  .ng-optgroup {
    color: color-mix(in srgb, #f44336, rgba(255, 255, 255, 0.3)) !important;
  }

  input {
    color: white;
    font-size: 1em;
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    // background-color: var(--mat-option-selected-state-label-text-color);
    background-color: rgba(255, 255, 255, 0.45);
  }
}

.ng-dropdown-panel {
  background: rgba(70, 70, 70, 1);
  padding: 0.5em;

  .ng-option {
    padding: 0.5em 0;
  }

  .ng-dropdown-panel-items {
    .ng-option.ng-option-disabled {
      color: rgba(255, 255, 255, 0.45);
    }
  }

  .ng-star-inserted {
    color: white;
  }
}
